.App {
  text-align: center;
  align-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-menu {
  flex-grow: 1;
  width: calc(100% - 240px);
  padding-left: 240px;
}

body {
  background-color: rgb(249 250 251);
}

#auth-main {
  background-image: url("/src/assets/images/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

::selection {
  background: #195cb5;
  color: white;
  font-weight: bold;
}

::-moz-selection {
  background: #195cb5;
  color: white;
  font-weight: bold;
}

.table-fix-head thead {
  position: sticky;
  top: -2px;
}
