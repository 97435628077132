@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Para aplicar estilos al body cuando el modal está visible */
body:has(div[id="root"][aria-hidden="true"]) {
  overflow-y: hidden !important;
}

input[type="file"]::file-selector-button {
  border: none;
  height: 100%;
  color: #fff;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  background: rgb(79, 70, 229);
}

input[type="file"]::file-selector-button:hover {
  background: #0d45a5;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 10px;
}
